import { EventEmitter } from './EventEmitter';

class RAF extends EventEmitter {
    constructor() {
        super();
        this.tick = this.tick.bind(this);
        this.rafId = null;
    }

    start() {
        if (this.run) return;
        this.run = true;
        this.tick();
    }

    stop() {
        this.run = false;
        if (this.rafId) cancelAnimationFrame(this.rafId);
    }

    onTick(callback) {
        this.on('tick', callback);
        return () => this.off('tick', callback);
    }

    tick() {
        this.emit('tick', this.clock);
        if (this.run) this.rafId = requestAnimationFrame(this.tick);
    }
}

export default new RAF();
