
import { Capacitor, Plugins, StatusBarStyle } from '@capacitor/core';
import { mapGetters, mapMutations } from 'vuex';
import RAF from '@/modules/RAF';
import { countPendingDeliveries } from '../modules/PendingDeliveries';
import WhitelabelTopbar from '../components/WhitelabelTopbar.vue';
import WhitelabelFooter from '../components/WhitelabelFooter.vue';

const { StatusBar } = Plugins;

export default {
    components: {
        WhitelabelTopbar,
        WhitelabelFooter,
    },
    data() {
        return {
            online: true,
            WhitelabelTopbarnline: true,
            infoProvided: false,
            localMarketplace: null,
            pendingOrders: 0,
        };
    },
    computed: {
        ...mapGetters('toast', ['msg']),
        ...mapGetters('sheet', ['type']),
        ...mapGetters('modal', { modalType: 'type' }),
        ...mapGetters('sideDrawer', ['sideDrawerType']),
        ...mapGetters('order', ['address', 'deliveryMethod', 'restaurantLocation', 'deliveryOptionId']),
        ...mapGetters(['splashSeen', 'loading', 'statusBarVisible']),
        ...mapGetters(['loggedInUser', 'transition', 'vat']),
        ...mapGetters('supplier', ['isWhitelabel']),

        initials() {
            if (this.loggedInUser) {
                if (this.loggedInUser.name) {
                    var parts = this.loggedInUser.name.split(' ');
                    var initials = '';
                    for (var i = 0; i < parts.length; i++) {
                        if (parts[i].length > 0 && parts[i] !== '') {
                            initials += parts[i][0];
                        }
                    }
                    return initials;
                }
            }
            return null;
        },
        routeName() {
            return this.$route.name;
        },
        showCartBar() {
            return this.routeName === 'supplier-slug';
        },
        isCheckout() {
            return this.routeName === 'checkout';
        },
        delivery() {
            return this.deliveryMethod === 'delivery';
        },
        lock() {
            return this.type;
        },
        isNative() {
            return Capacitor.isNative;
        },
        pageGray() {
            return this.routeName === 'order-uuid';
        },
        activeOrdersLength() {
            if (this.loggedInUser) {
                return this.loggedInUser.has_orders;
                // return this.loggedInUser.orders.filter(x => x.state === 'confirmed' || x.state === 'placed' || x.state === 'delivery-ongoing' || x.state === 'auto-confirmed' || x.state === 'completed').length;
            }
            return 0;
        },
        loggedInAsTribeAdmin() {
            if (this.loggedInUser) {
                if (this.loggedInUser.tribe_admin) {
                    return true;
                }
            }
            return false;
        },
        corporateAccount() {
            if (this.loggedInUser) {
                return this.loggedInUser.corporate_account;
            }
            return null;
        },
        marketplace() {
            if (this.localMarketplace) {
                return this.localMarketplace;
            }
            let marketplace = null;
            if (typeof window !== 'undefined') {
                marketplace = localStorage.getItem('marketplace');
            }
            return marketplace;
        },
    },
    watch: {
        $route() {
            this.closeSheet();
            this.closeToast();
        },
        type(type, typeOld) {
            if (!type && this.delivery && !this.address && typeOld !== 'Cart') {
                this.setDeliveryMethod('pickup');
            }
        },
        restaurantLocation: {
            handler(newRestaurantLocation, oldRestaurantLocation) {
                if (
                    newRestaurantLocation &&
                    oldRestaurantLocation &&
                    newRestaurantLocation.slug !== oldRestaurantLocation.slug
                ) {
                    this.clearCart();
                }
            },
            deep: true,
        },
    },
    beforeDestroy() {
        this.VueOnlineRemoveListeners();
    },
    async mounted() {
        // WILDCARD SUBDOMAIN LOGIC
        let subdir = window.location.host.split('.')[0].toLowerCase();
        let localStorageMarketplace = localStorage.getItem('marketplace');

        countPendingDeliveries(this.loggedInUser ? this.loggedInUser.orders : []).then(res => {
            if (res > 99) {
                this.pendingOrders = '+';
            } else {
                this.pendingOrders = res;
            }
        });

        if (localStorageMarketplace !== subdir) {
            if (
                !subdir.includes('tribehappiness') &&
                !subdir.includes('localhost') &&
                subdir !== 'app' &&
                !subdir.includes('onrender') &&
                !subdir.includes('tribe-app')
            ) {
                if (subdir) {
                    // this.setMarketplace(subdir);
                    this.$router.push('/');
                    localStorage.setItem('marketplace', subdir);
                    this.localMarketplace = subdir;
                }
            } else {
                localStorage.removeItem('marketplace');
                // localStorage.setItem('marketplace', null);
            }
        }

        // Register tracking
        try {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('config', 'AW-11353743417');
        } catch ($err) {
            console.log('Could not add config: AW-11353743417');
        }

        if (this.isNative) {
            StatusBar.setStyle({ style: StatusBarStyle.Light }).catch(err => {
                console.log('err', err);
            });
        }
        await this.$nextTick();
        RAF.start();
        await this.$nextTick();
        this.appHeight(this.$refs.app.offsetHeight);

        // Initialize datalayer
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({ event: 'event_name' });

        this.VueOnlineSetListeners();

        window.intercomSettings = {
            app_id: process.env.NUXT_ENV_INTERCOM_APP_ID,
            hide_default_launcher: window.innerWidth < 767 || this.isWhitelabel,
            horizontal_padding: 50,
            vertical_padding: 30,
        };
        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                var l = function () {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + process.env.NUXT_ENV_INTERCOM_APP_ID;
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                };
                if (document.readyState === 'complete') {
                    l();
                } else if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })();
        Intercom('onShow', this.intercomShow);

        // Message modals
        setTimeout(() => {
            if (!this.modalType) {
                if (
                    this.loggedInUser &&
                    this.loggedInUser.corporate_account &&
                    !this.loggedInUser.corporate_account.invoice_enabled
                ) {
                    // this.setModal({ type: 'HowToInvoice' });
                }
                if (this.loggedInUser && !this.loggedInUser.corporate_account) {
                    this.setModal({ type: 'HowToConnectCorporateAccount' });
                }
            }
        }, 3000);

        // VAT pricing localStorage logic
        let localStorageVat = localStorage.getItem('vat');
        if (localStorageVat) {
            this.setVat(localStorageVat);
        } else {
            this.setVat('excl');
        }
    },
    methods: {
        ...mapMutations('sheet', ['closeSheet']),
        ...mapMutations('toast', ['closeToast']),
        ...mapMutations('order', ['setDeliveryMethod', 'clearCart']),
        ...mapMutations('sideDrawer', ['closeSideDrawer']),
        ...mapMutations(['appHeight']),
        ...mapMutations('modal', ['setModal']),
        ...mapMutations(['setVat']),
        ...mapMutations(['setMarketplace']),
        showIntercomDialog() {
            if (!this.isWhitelabel) {
                Intercom('showNewMessage');
            }
        },
        VueOnlineSetListeners() {
            window.addEventListener('online', this.VueOnlineUpdateOnline);
            window.addEventListener('offline', this.VueOnlineUpdateOnline);
        },
        VueOnlineRemoveListeners() {
            window.removeEventListener('online', this.VueOnlineUpdateOnline);
            window.removeEventListener('offline', this.VueOnlineUpdateOnline);
        },
        VueOnlineUpdateOnline() {
            if (typeof window.navigator.onLine === undefined) {
                this.online = true;
            } else {
                this.online = window.navigator.onLine;
            }
        },
        intercomShow() {
            if (this.$route.name !== 'user-settings-support' && window.innerWidth < 767) {
                this.$router.push('/user-settings/support');
            }
            if (window.innerWidth > 767 && typeof Intercom === 'function' && this.loggedInUser) {
                if (!this.infoProvided) {
                    Intercom('update', {
                        name: this.loggedInUser.name,
                        email: this.loggedInUser.email,
                        phone: this.loggedInUser.phone_number,
                        corporate: this.loggedInUser.corporate_account ? this.loggedInUser.corporate_account.name : '',
                        user_id: this.loggedInUser.id,
                    });
                    this.infoProvided = true;
                }
            }
        },
        openLoginModal() {
            // this.setModal({ type: 'Login' });
            this.setModal({ type: 'NewLogin' });
        },
        openCreateUserModal() {
            this.setModal({ type: 'CreateUser' });
        },
        openUserModal() {
            this.setModal({ type: 'User' });
        },
        goHome() {
            this.$router.push('/');
        },
        goHappy() {
            this.$router.push('/happy-lunch');
        },
        goBreakfast() {
            this.$router.push('/happy-breakfast');
        },
        goTakeaway() {
            this.$router.push('/happy-takeaway');
        },
        goMoments() {
            this.$router.push('/happy-moments');
        },
        goFruit() {
            this.$router.push('/happy-fruit');
        },
        goMeetings() {
            this.$router.push('/happy-meetings');
        },
        openChangeCompanyModal() {
            this.setModal({ type: 'ChangeCompany' });
        },
        goOrders() {
            this.$router.push('/orders');
        },
    },
};
