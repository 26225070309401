import dayjs from 'dayjs';

export async function countPendingDeliveries(orders) {
    const now = dayjs().unix(); 
    let pendingOrdersCount = 0;
    for (let i = 0; i < orders.length; i++) {
        if (orders[i].preorder_time != null) {
            const formatted = dayjs(orders[i].preorder_time);
            // Check if the expected delivery date is in the future
            if (formatted.unix() > now) {
                pendingOrdersCount++;
            }
        }
    }

    return pendingOrdersCount
}
