export default async function ({ store, req, redirect, route }) {
    // Skip on server-side for static pages
    if (process.server && !req) return;

    const host = process.server ? req.headers.host : window.location.host;
    const subdomain = host.split('.')[0];

    // Skip for non-supplier domains
    if (!subdomain || ['www', 'app', 'tribehappiness', 'localhost', 'tribe-app'].includes(subdomain)) {
        store.commit('supplier/setCurrentSupplier', null);
        store.commit('supplier/setTheme', null);
        return;
    }

    // Always fetch supplier data if we don't have it or if we're on a supplier page
    if (!store.state.supplier.currentSupplier || route.name === 'supplier-slug') {
        console.log('Fetching supplier data for:', subdomain);
        const data = await store.dispatch('supplier/fetchSupplierBySubdomain', subdomain);
        
        if (!data) {
            console.log('No supplier data found, redirecting to main site');
            redirect('https://tribehappiness.com');
            return;
        }

        // If we're on the homepage of the subdomain, redirect to the supplier page
        if (route.path === '/') {
            const supplierSlug = data.supplier.locations[0].slug;
            redirect(`/supplier/${supplierSlug}`);
        }
    }
}
