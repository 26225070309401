export class EventEmitter {
    constructor() {
        this.listeners = {};
    }

    on(event, callback) {
        if (this.listeners[event]) this.listeners[event].push(callback);
        else this.listeners[event] = [callback];

        return () => this.$off(event, callback);
    }

    off(event, callback) {
        if (this.listeners[event].includes(callback))
            this.listeners[event].splice(this.listeners[event].indexOf(callback), 1);
    }

    emit(event, params) {
        if (this.listeners[event]) this.listeners[event].forEach(c => c(params));
    }

    destroy() {
        Object.keys(this.listeners).forEach(event => {
            this.listeners[event].forEach(callback => {
                this.off(event, callback);
            });
        });
    }
}

export default new EventEmitter();
